<template>
  <moe-page title="问题分类管理">
    <moe-table ref="categoryTable" url="/problem/assortList" :params="categoryParams" :number-show="false" :showPage="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true">新增分类</el-button>
      </template>

      <el-table-column prop="id" label="分类ID" min-width="80" />
      <el-table-column prop="assort" label="分类名称" min-width="130">
        <template slot-scope="{ row }">
          <div v-if="row.edit">
            <el-input v-model.trim="row.assort" placeholder="请输入分类名称" maxlength="50" clearable></el-input>
          </div>
          <div v-else-if="!row.edit">{{ row.assort }}</div>
        </template>
      </el-table-column>
      <el-table-column label="是否在APP展示" min-width="130">
        <template slot-scope="{ row }">
          <moe-switch @change="(e) => assortShelf(row, e)" :activeValue="1" :inactiveValue="2" v-model="row.status" activeText="展示" inactiveText="不展示"></moe-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="130">
        <div class="moe-table__btns df aic jcc" slot-scope="{ row }">
          <template v-if="row.edit">
            <el-button type="success" size="mini" @click="updateAssort(row)">确定</el-button>
            <el-button type="info" size="mini" @click="handleCancel()">取消</el-button>
          </template>
          <el-button v-else-if="!row.edit" type="primary" size="mini" icon="el-icon-edit" @click="row.edit = !row.edit">编辑</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>

    <moe-dialog :show="showDialog" title="新增分类名称" width="70%" @close="handleDialogClose()">
      <moe-form ref="categoryAddForm" :showBack="false" :model="categoryAddParams" :rules="rules">
        <el-form-item label="分类名称" prop="assort">
          <el-input placeholder="请输入分类名称" v-model.trim="categoryAddParams.assort" clearable />
        </el-form-item>
      </moe-form>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取 消</el-button>
        <el-button type="primary" @click="handleSubmit()">{{ categoryAddLoading ? '请稍候...' : '确 定' }}</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'FqaManageCategoryList',
  data() {
    const verify = this.$moe_verify.verifyForm
    return {
      categoryParams: {
        pageNum: 1,
      },
      showDialog: false,
      categoryAddLoading: false,
      categoryAddParams: {
        assort: '',
        status: 2,
      },
      rules: {
        assort: verify.isEmpty('请输入分类名称'),
      },
    }
  },
  methods: {
    handleDialogClose() {
      this.$refs['categoryAddForm'].resetFields();
      this.showDialog = false;
    },
    handleSubmit() {
      this.$refs['categoryAddForm'].validate(() => {
        this.categoryAddLoading = true;

        this.$moe_api.FQA_API.addAssort({ assort: this.categoryAddParams.assort }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('新增成功');
            this.handleDialogClose();
            this.handleCancel();
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.categoryAddLoading = false;
        })
      })
    },
    handleCancel() {
      this.$refs['categoryTable'].searchData();
    },
    /** 修改问题分类 */
    updateAssort({ assort, id, status }) {
      if (!assort) {
        return this.$moe_msg.warning('请输入分类名称')
      }
      this.$moe_api.FQA_API.updateAssort({ id, assort, status }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑成功');
          this.handleCancel();
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    /** 问题分类上下架 */
    assortShelf({ id }, status) {
      this.$moe_api.FQA_API.assortShelf({ id, status }).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑成功');
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    /** 删除问题分类 */
    handleDelete({ assort, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${assort}”吗？`, () => {
        this.$moe_api.FQA_API.delAssort({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.handleCancel();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    }
  }
}
</script>